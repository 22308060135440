import { SITE_SETTING_SUCCESS } from "./../type";

const INIT_STATE_SITE_SETTING = {
  siteSettingInfo: null
};


const sitesettingReducer = (state = INIT_STATE_SITE_SETTING, action) => {
  switch (action.type) {
    case SITE_SETTING_SUCCESS:
      return { siteSettingInfo: action.payload };
    default:
      return state;
  }
};

export default sitesettingReducer;
