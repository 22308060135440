import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import '../public/css/bundle.css';
import '../styles/style.scss';
import '../styles/responsive.scss';

import React from "react";
import Router from 'next/router';
import NProgress from 'nprogress';
import { Provider as ReduxProvider } from 'react-redux';
import { createWrapper } from "next-redux-wrapper";
import { connect } from 'react-redux';

import store from '../redux/store';
import DefaultLayout from '../components/common/layouts/DefaultLayout';

Router.events.on('routeChangeStart', (url) => {
  NProgress.start()
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp = ({ Component, pageProps }) => {
  
  return (
    <>
      <ReduxProvider store={store}>
          <DefaultLayout>
            <Component {...pageProps} />
          </DefaultLayout>
      </ReduxProvider>
    </>
  );
}

const makeStore = () => store;
const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(connect(null, null)(MyApp));
