export const apiUrl = 'https://api.rafiulislam.com';
// export  const apiUrl = 'http://localhost:9000';

export const baseUrl = "http://localhost:3000";
// export const baseUrl = "https://rafiulislam.com/";

export const GA_TRACKING_ID = 'UA-158759976-1';
export const appName = "MRI29 Website";
export const appWebsite = "https://rafiulislam.com/";
export const devName = "Md. Rafiul Islam";
export const devWebsite = "https://rafiulislam.com/";
export const classPrefix = "mri";
export const googleMapApiKey = "AIzaSyDBe0SDmy3dhz8abKvImFqvif8fAVHYts8";
export const googleMapDefaultLocation = { 
    lat: 23.7808875, 
    lng: 90.2792371
};
export const googleMapCountryList = ['bd']
export const googleMapDefaultZoom = 14;
export const fbID = "359433559301511";
export const fbPageID = "105751340935336";

export const siteTitle = 'MRI29';
export const siteTagLine = 'Md. Rafiul Islam | Portfolio ';
export const metaSiteTitle = siteTagLine + " | " + siteTitle;
export const metaDesc = "Portfolio website of Md. Rafiul Islam";
export const metaKeywords = "Md. Rafiul Islam, BPO, Software Developer, Web Developer, Full Stack Web Developer, Frontend Developer, Backend Developer, Portfolio website, Bangladesh, Outsourcing, development, PHP, Laravel, Node Js, React Js, Next Js, Python";