import React, { useState } from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appName, baseUrl } from '../../../config/config';
import HeaderMenu from './HeaderMenu';
import CustomImage from '../image/CustomImage';
import { faBars, faLocationArrow, faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = ({ auth, siteSetting }) => {

    const [menuActive, setMenuActive] = useState(false);

    const handleMenuButton = () => {
        setMenuActive(true);
    };
    const handleMenuClose = () => {
        setMenuActive(false);
    };


    return (<>
        <header className="header-area bg-gradient-blue-light-blue position-relative py-3 py-lg-0">
            <div className="container">
                <div className="header-container">
                    <div className="row align-items-center">
                        <div className="col-3 d-none d-xs-block">
                            <button type="button" className="header-menu-button text-white fs-32 lh-40" onClick={() => handleMenuButton()}>
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                        </div>
                        <div className="col-6 col-lg-3 text-center text-lg-start">
                            <div className="header-logo position-relative d-flex justify-content-center justify-content-lg-start">
                                <Link href="/">
                                    <a className="d-inline-flex w-100 h-100 position-relative">
                                        <CustomImage
                                            src={baseUrl + "/images/logo-white.png"}
                                            alt={appName}
                                            layout="fill"
                                            objectFit="contain"
                                            objectPosition="left"
                                        />
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 text-center d-xs-none">
                            <div className="header-menu-area">
                                <HeaderMenu />
                            </div>
                        </div>
                        <div className="col-3 text-end">
                            <div className="header-right-area">
                                <Link href="/">
                                    <a className="d-inline-flex py-2 px-3 text-sky-blue text-uppercase radius-12 bg-white fw-semibold fs-20 lh-32">
                                        <span className="text d-xsm-none">LETS TALK</span>
                                        <span className="icon ms-2">
                                            <FontAwesomeIcon icon={faLocationArrow} />
                                        </span>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <button type="button" className={`menu-backdrop d-none d-xs-block position-fixed start-0 top-0 w-100 h-100 border-0 ${menuActive ? 'active' : ''}`} onClick={() => handleMenuClose()}></button>
        <div className={`header-menu-fixed-area d-none d-xs-block position-fixed d-flex flex-column ${menuActive ? 'active' : ''}`}>
            <button type="button" className="header-close-button" onClick={() => handleMenuClose()}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="header-logo position-relative">
                <Link href="/">
                    <a>
                        <CustomImage
                            src={baseUrl + "/images/logo.png"}
                            alt={appName}
                            layout="fill"
                            objectFit="contain"
                        />
                    </a>
                </Link>
            </div>
            <div className="header-menu-area flex-grow-1">
                <HeaderMenu />
            </div>
        </div>
    </>);
};


const mapStateToProps = state => {
    return {
        auth: state.auth,
        siteSetting: state.siteSetting.siteSettingInfo
    };
};


export default connect(mapStateToProps, null)(Header);