import React from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const HeaderMenu = () => {

    return (
        <ul className="d-flex align-items-center justify-content-center fw-semibold">
            <li className="mx-3">
                <Link href="/">
                    <a className="d-block py-4 px-0 text-green-hover fs-18 lh-24 text-white position-relative text-nowrap active">
                        <span className="text">Home</span>
                    </a>
                </Link>
            </li>
            <li className="position-relative mx-3">
                <Link href="/">
                    <a className="d-block py-4 px-0 text-green-hover fs-18 lh-27 text-white position-relative text-nowrap">
                        <span className="text">Services</span>
                        <span className="icon ms-2">
                            <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                    </a>
                </Link>
                <ul className="position-absolute text-start bg-white transition-all-3 py-2 px-1">
                    <li>
                        <Link href="/">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">Business Email Services</span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">Startup Solution</span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">On-Demand IT Staffing Services</span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">Server Management</span>
                            </a>
                        </Link>
                    </li>
                </ul>
            </li>
            <li className="position-relative mx-3">
                <Link href="/">
                    <a className="d-block py-4 px-0 text-green-hover fs-18 lh-27 text-white position-relative text-nowrap">
                        <span className="text">Our Products</span>
                        <span className="icon ms-2">
                            <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                    </a>
                </Link>
                <ul className="position-absolute text-start bg-white transition-all-3 py-2 px-1">
                    <li>
                        <Link href="/courier-management-software">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">Courier Management Software</span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">POS and Inventory Solutions</span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">Multi-Vendor Ecommerce Solution</span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">Grocery E-commerce</span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/">
                            <a className="d-block px-3 py-2 fs-19 lh-24 text-nowrap">
                                <span className="text">Food Delivery Solution</span>
                            </a>
                        </Link>
                    </li>
                </ul>
            </li>
            
            <li className="mx-3">
                <Link href="/">
                    <a className="d-block py-4 px-0 text-green-hover fs-18 lh-24 text-white position-relative text-nowrap">
                        <span className="text">About Us</span>
                    </a>
                </Link>
            </li>
            <li className="mx-3">
                <Link href="/">
                    <a className="d-block py-4 px-0 text-green-hover fs-18 lh-24 text-white position-relative text-nowrap">
                        <span className="text">Contact Us</span>
                    </a>
                </Link>
            </li>
        </ul>
    );
};


export default HeaderMenu;